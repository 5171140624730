import { React, useState, useEffect } from 'react'
import InputField from '../components/InputFields/InputField';
import PulseLoader from '../components/PulseLoader';
// import ImageField from '../components/InputFields/ImageField';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import MultipleImageField from "../components/InputFields/MultipleImageField";
import { addBrandMain, updateBrandMain } from '../actions/brandMainActions';


const BrandForm = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { FormName, Editable } = props;
  const { brands, redirect, loading } = useSelector(state => state.Brand);
  const [brand, setBran] = useState({});
  const [Brand, setBrand] = useState({
    name: '',
    photo: [],
  });
  const [fileLoading, setFileLoading] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line
      const bran = brands.find(brand => brand.id == id);
      if (bran) {
        setBran(bran);
        setBrand({
          name: bran.name,
          photo: bran.photo,
          priorityNumber: bran?.priorityNumber
        });
      }
    }

    // eslint-disable-next-line
  }, [id, brands]);

  useEffect(() => {
    if (redirect) {
      navigate('/brands');
    }
    // eslint-disable-next-line
  }, [redirect]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBrand({ ...Brand, [name]: value });
  };

  const handleAddBrand = (e) => {
    e.preventDefault();
    if (!Brand.name) {
      toast.error('Please enter brand name');
      return;
    }
    if (!Brand.priorityNumber) {
      toast.error('Please enter priority number');
      return
    }
    Brand.photo = files;
    dispatch(addBrandMain(Brand));
    // toast.success('Brand added');
  }

  const handleUpdateBrand = (e) => {
    e.preventDefault();
    if (!Brand.name) {
      toast.error('Please enter brand name');
      return;
    }
    if (!Brand.priorityNumber) {
      toast.error('Please enter priority number');
      return
    }
    Brand.photo = files;
    dispatch(updateBrandMain(brand._id, Brand));
  }


  return (
    <>
      <div className="content-wrapper container-fluid">
        {
          loading ? (<PulseLoader />) : (
            <div className="row p-5 justify-content-center">
              <div className="col-md-5">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{FormName}</h5>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <InputField label="Name" name='name' value={Brand.name} onChange={handleInputChange} required={true} type='text' placeholder="Name here..." />
                          </div>
                          <div className="mb-3">
                            <InputField label="Priority Number" name='priorityNumber' value={Brand.priorityNumber} onChange={handleInputChange} required={true} type='text' placeholder="Priority number here..." />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <MultipleImageField name="photos" setFiles={setFiles} data={Brand.photo} setFileLoading={setFileLoading} size="600 x 600 px" />
                          </div>
                        </div>
                      </div>

                      {
                        Editable === 'true' ? <button type="submit" onClick={handleUpdateBrand} className="btn btn-primary" disabled={fileLoading ? true : false}>
                          Update Brand
                        </button> : <button type="submit" onClick={handleAddBrand} className="btn btn-primary" disabled={fileLoading ? true : false}>
                          Save
                        </button>
                      }

                    </form>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div className="content-backdrop fade"></div>
      </div>
    </>
  )
}

export default BrandForm